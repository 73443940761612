/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $.support.transition = false;
        $('#navbar').on('show.bs.collapse', function () {
          $(document.body).addClass("noscroll");
          $('.navbar-default').css('z-index', '50');
          $('.logo').css('z-index', '0');
        });
        $('#navbar').on('hide.bs.collapse', function () {
          $(document.body).removeClass("noscroll");
          $('.navbar-default').css('z-index', '40');
          $('.logo').css('z-index', '20');
        });

        $('.navititle a' ).click(function( event ) {
          event.preventDefault();
          event.stopPropagation();
        });

        equalheight = function(container){

        var currentTallest = 0,
             currentRowStart = 0,
             rowDivs = [],
             $el,
             topPosition = 0;
         $(container).each(function() {

           $el = $(this);
           $($el).height('auto');
           topPostion = $el.position().top;

           if (currentRowStart !== topPostion) {
             for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
               rowDivs[currentDiv].height(currentTallest);
             }
             rowDivs.length = 0; // empty the array
             currentRowStart = topPostion;
             currentTallest = $el.height();
             rowDivs.push($el);
           } else {
             rowDivs.push($el);
             currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
          }
           for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
             rowDivs[currentDiv].height(currentTallest);
           }
         });
        };

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.slider').slick({
          lazyLoad: 'ondemand',
          autoplay: true,
          dots: true,
          autoplaySpeed: 7000,
          speed: 1000,
          fade: true,
          cssEase: 'linear',
          infinite: true,
          pauseOnHover: false,
          waitForAnimate: false
        });

        // Run code for each element
         $('.embed-responsive').each( function (){

         // Get ar
         var ar = $(this).attr('data-aspectratio');

         // Check for existance and set padding-bottom
         if (typeof ar !== typeof undefined && ar !== false ) {
           $(this).css('padding-bottom', (1/ar)*100+'%');
         }

         });

         if ( $( ".gallery" ).length ) {

          var container_gallery = document.querySelector('.gallery');

          imagesLoaded( container_gallery, function() {
          var msnry = new Masonry( container_gallery, {
              // options...
              // columnWidth: ".gallery-item",
              itemSelector: '.gallery-item'
            });
          });//

        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS


        $(window).load(function() {
          equalheight('.wrap-anchor');
        });


        $(window).resize(function(){
          equalheight('.wrap-anchor');
        });
      }
    },
    // Home page
    'archive': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $(window).load(function() {
          equalheight('.wrap-anchor');
        });


        $(window).resize(function(){
          equalheight('.wrap-anchor');
        });
      }
    },
    'tax_palveluluokka': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $(window).load(function() {
          equalheight('.palvelu-item');
        });


        $(window).resize(function(){
          equalheight('.palvelu-item');
        });

        showme = function(index) {

          var new_marker = markerarray[index];
          if(prev_marker !== '') {
            if (prev_marker.getAnimation() !== null) {
                prev_marker.setAnimation(null);
                new_marker.setAnimation(google.maps.Animation.BOUNCE);
                prev_marker = new_marker;
            }
          } else {
            new_marker.setAnimation(google.maps.Animation.BOUNCE);
            prev_marker = new_marker;
          }

        };

        $(document).ready(function() {
          $(".palvelu-item").hover(function() {
            showme($(this).attr("id"));
          });
        });
      }
    },
    'attachment': {
      init: function() {

        var container = document.querySelector('.gallerysidebar');
        var msnry;

        imagesLoaded( container, function() {
          msnry = new Masonry( container, {
            // options...
            columnWidth: ".grid-sizer",
            itemSelector: ".gallerysidebarthumb"
          });
        });

      },
      finalize: function() {

      }
    },
    'page_template_template_kuvagalleria': {
      init: function() {

        // console.log('jep');

        var container = document.querySelector('.gallerywrap');

        imagesLoaded( container, function() {
        var msnry = new Masonry( container, {
            // options...
            columnWidth: ".grid-sizer",
            itemSelector: '.gallerythumb'
          });
        });

      },
      finalize: function() {
        // console.log('jep');

      }
    },
    'page_template_template_tapahtumakalenteri': {
      init: function() {

      },
      finalize: function() {
        $('.lisatiedot p:empty').remove();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

/* Script for asynchronus load of sharing buttons */

 (function(w, d, s) {

   function go(){
    var js, fjs = d.getElementsByTagName(s)[0], load = function(url, id) {
    if (d.getElementById(id)) {return;}
      js = d.createElement(s); js.src = url; js.id = id;
      fjs.parentNode.insertBefore(js, fjs);
    };
        //Facebook
    load('//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.7&appId=1379074455440593', 'facebook-jssdk');
        //Google+
    load('https://apis.google.com/js/plusone.js', 'gplus1js');
        //Twitter
    load('//platform.twitter.com/widgets.js', 'tweetjs');
        //LinedIN
    // load('//platform.linkedin.com/in.js', 'lnkdjs');
        //Pinterest
    load('//assets.pinterest.com/js/pinit.js', 'pinitjs');
    }
  if (w.addEventListener) { w.addEventListener("load", go, false); }
    else if (w.attachEvent) { w.attachEvent("onload",go); }

}(window, document, 'script'));
